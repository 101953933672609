import React, { useState } from 'react';
import classNames from 'classnames/bind';
import { useParams, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import styles from '../../../styles/form.module.scss';
import API from '../../../helpers/API';
import TextInput from '../../../components/ui/TextInput';
import Button from '../../../components/ui/Button';
import TopBar from '../../../components/layout/TopBar';
import BottomBar from '../../../components/layout/BottomBar';
import ContentWrapper from '../../../components/layout/ContentWrapper';
import FormRow from '../../../components/ui/FormRow';

const cx = classNames.bind(styles);

const Page = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [info, setInfo] = useState({
    key: '',
    value: '',
    memo: '',
  });

  const handleChange = (e) => {
    setInfo({
      ...info,
      [e.target.name]: e.target.value,
    });
  };

  const validation = () => {
    if (info.key.trim() === '') {
      toast.error('Key가 입력되지 않았습니다.');
      return false;
    }
    if (info.value.trim() === '') {
      toast.error('Value가 입력되지 않았습니다.');
      return false;
    }

    return true;
  };

  const doSave = () => {
    if (!validation()) return;

    const data = {
      key: info.key,
      value: info.value,
      memo: info.memo,
    };

    API.post(`/robots/${id}/metadata`, data).then(({ success }) => {
      if (success) {
        navigate(`/robots/${id}`);
      } else {
        toast.error('저장을 실패했습니다.');
      }
    });
  };

  return (
    <div className={cx('container')}>
      <TopBar>New Metadata</TopBar>
      <ContentWrapper>
        <FormRow title="Key" required>
          <TextInput name="key" width={240} value={info.key} placeholder="Key" onChange={handleChange} />
        </FormRow>
        <FormRow title="Value" required>
          <TextInput name="value" width="100%" value={info.value} placeholder="Value" onChange={handleChange} />
        </FormRow>
        <FormRow title="Memo">
          <TextInput name="memo" width="100%" value={info.memo} placeholder="Memo" onChange={handleChange} />
        </FormRow>
      </ContentWrapper>
      <BottomBar>
        <Button title="Back" onClick={() => navigate(-1)} />
        <Button title="Save" onClick={doSave} />
      </BottomBar>
    </div>
  );
};

export default Page;
