import React, { useEffect, useState } from 'react';
import classNames from 'classnames/bind';
import { useParams, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { IoImageOutline, IoSearchOutline } from 'react-icons/io5';
import commaNumber from 'comma-number';

import styles from '../../../styles/form.module.scss';
import API from '../../../helpers/API';
import TextInput from '../../../components/ui/TextInput';
import Button from '../../../components/ui/Button';
import Table from '../../../components/ui/Table';
import TopBar from '../../../components/layout/TopBar';
import BottomBar from '../../../components/layout/BottomBar';
import ContentWrapper from '../../../components/layout/ContentWrapper';
import FormRow from '../../../components/ui/FormRow';
import SearchAccount from '../../../components/modals/SearchAccount';
import MetadataList from './MetadataList';

const cx = classNames.bind(styles);

const Page = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [info, setInfo] = useState();
  const [showAccountList, setShowAccountList] = useState(false);
  const [isChangeThumbnail, setChangeThumbnail] = useState(false);

  useEffect(() => {
    API.get(`/robots/${id}`).then(({ success, data }) => {
      if (success) {
        setInfo({
          name: data.name,
          model: data.model,
          commander: data.commander,
          color: data.color,
          thumbnailUrl: data.thumbnailUrl,
        });
      }
    });
  }, [id]);

  const handleChange = (e) => {
    setInfo({
      ...info,
      [e.target.name]: e.target.value,
    });
  };

  const toggleCheckbox = (name) => {
    setInfo({
      ...info,
      [name]: !info[name],
    });
  };

  const toggleAccountList = () => {
    setShowAccountList(!showAccountList);
  };

  const handleFileInput = (e) => {
    setInfo({
      ...info,
      [e.target.name]: e.target.files[0],
    });
    setChangeThumbnail(true);
  };

  const selectAccount = (selected) => {
    toggleAccountList();

    if (selected) {
      setInfo({
        ...info,
        commander: selected,
      });
    }
  };

  const doDelete = () => {
    if (!confirm('삭제하시겠습니까?')) return;

    API.delete(`/robots/${id}`).then(({ success }) => {
      if (success) {
        navigate('/robots');
      } else {
        toast.error('삭제를 실패했습니다.');
      }
    });
  };

  const validation = () => {
    if (info.name.trim() === '') {
      toast.error('로봇명이 입력되지 않았습니다.');
      return false;
    }
    if (info.color.trim() === '') {
      toast.error('색상이 입력되지 않았습니다.');
      return false;
    }
    return true;
  };

  const doSave = () => {
    if (!validation()) return;

    const formData = new FormData();
    formData.append('name', info.name);
    formData.append('commanderId', info.commander.id);
    formData.append('color', info.color);
    if (info.thumbnailUrl && isChangeThumbnail) {
      formData.append('thumbnail', info.thumbnailUrl);
    }

    API.patch(`/robots/${id}`, formData).then(({ success }) => {
      if (success) {
        navigate('/robots');
      } else {
        toast.error('저장을 실패했습니다.');
      }
    });
  };

  const modelColumns = [
    {
      key: 'maker',
      name: 'Maker',
      exp: (item) => item.maker,
      style: { flex: 1 },
    },
    {
      key: 'name',
      name: 'Name',
      exp: (item) => item.name,
      style: { flex: 1 },
    },
    {
      key: 'agentType',
      name: 'Agent Type',
      exp: (item) => item.agentType ?? '-',
      width: 120,
      align: 'center',
    },
    {
      key: 'iconUrl',
      name: 'Icon',
      exp: (item) => (item.iconUrl ? <IoImageOutline size={22} /> : '-'),
      align: 'center',
      width: 88,
      onPress: (item) => item.iconUrl && window.open(item.iconUrl),
    },
    {
      key: 'thumbnailUrl',
      name: 'Thumb',
      exp: (item) => (item.thumbnailUrl ? <IoImageOutline size={22} /> : '-'),
      align: 'center',
      width: 88,
      onPress: (item) => item.thumbnailUrl && window.open(item.thumbnailUrl),
    },
  ];

  const accountColumns = [
    {
      key: 'company',
      name: 'Company',
      exp: (item) => item.company ?? '-',
      width: 200,
    },
    {
      key: 'name',
      name: 'Name',
      exp: (item) => item.name,
      width: 120,
    },
    {
      key: 'email',
      name: 'Email',
      exp: (item) => item.email ?? '-',
      style: { flex: 1 },
    },
    {
      key: 'robots',
      name: 'Robots',
      exp: (item) => commaNumber(item.robots.length),
      width: 88,
      align: 'center',
    },
  ];

  return (
    <div className={cx('container')}>
      <TopBar>Robot Detail</TopBar>
      <ContentWrapper>
        {info && (
          <div>
            <FormRow title="Name" required>
              <TextInput name="name" width={240} value={info.name} placeholder="Name" onChange={handleChange} />
            </FormRow>
            <FormRow title="Model" required>
              <div className={cx('tableWrapperCell')}>
                <div className={cx('tableWrapper')}>
                  <Table absoluteFill={false} columns={modelColumns} data={[info.model]} />
                </div>
              </div>
            </FormRow>
            <FormRow title="Commander">
              <div className={cx('tableWrapperCell')}>
                <div className={cx('tableControl')}>
                  <div className={cx(['button', 'icon'])} onClick={toggleAccountList}>
                    <IoSearchOutline size={16} />
                  </div>
                  {showAccountList && (
                    <div className={cx('panelWrapper')}>
                      <SearchAccount callback={selectAccount} />
                    </div>
                  )}
                </div>
                {info.commander && (
                  <div className={cx('tableWrapper')}>
                    <Table absoluteFill={false} columns={accountColumns} data={[info.commander]} />
                  </div>
                )}
              </div>
            </FormRow>
            <FormRow title="Color" required>
              <TextInput name="color" width={80} value={info.color} placeholder="Color" onChange={handleChange} />
            </FormRow>
            <FormRow title="Thumbnail">
              {info.thumbnailUrl && !isChangeThumbnail && (
                <>
                  <Button title="Open" onClick={() => open(info.thumbnailUrl)} />
                  <Button title="Remove" onClick={() => setChangeThumbnail(true)} style={{ marginLeft: 8 }} />
                </>
              )}
              {(!info.thumbnailUrl || isChangeThumbnail) && (
                <input name="thumbnailUrl" type="file" onChange={handleFileInput} className={cx('attach')} />
              )}
            </FormRow>
            <FormRow title="Metadata">
              <MetadataList />
            </FormRow>
          </div>
        )}
      </ContentWrapper>
      <BottomBar>
        <div>
          <Button title="Back" onClick={() => navigate(-1)} />
          <Button title="Delete" onClick={doDelete} />
        </div>
        <Button title="Save" onClick={doSave} />
      </BottomBar>
    </div>
  );
};

export default Page;
