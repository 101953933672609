import React from 'react';
import classNames from 'classnames/bind';

import styles from './FormSectionTitle.module.scss';

const cx = classNames.bind(styles);

const FormSectionTitle = ({ title }) => {
  return (
    <div className={cx('container')}>
      <div className={cx('title')}>{title}</div>
    </div>
  );
};

export default FormSectionTitle;
