import React from 'react';
import classNames from 'classnames/bind';

import styles from './ContentWrapper.module.scss';

const cx = classNames.bind(styles);

const ContentWrapper = ({ children }) => {
  return <div className={cx('container')}>{children}</div>;
};

export default ContentWrapper;
