import React, { useEffect, useState } from 'react';
import classNames from 'classnames/bind';
import { useNavigate } from 'react-router-dom';
import commaNumber from 'comma-number';
import moment from 'moment';

import styles from '../../styles/list.module.scss';
import API from '../../helpers/API';
import Button from '../../components/ui/Button';
import Table from '../../components/ui/Table';
import TopBar from '../../components/layout/TopBar';
import BottomBar from '../../components/layout/BottomBar';
import ContentWrapper from '../../components/layout/ContentWrapper';

const cx = classNames.bind(styles);

const Page = () => {
  const navigate = useNavigate();
  const [info, setInfo] = useState();

  useEffect(() => {
    API.get('/accounts').then(({ success, data }) => {
      if (!success) {
        setInfo([]);
        return;
      }

      data.sort((a, b) => {
        // company가 null일 경우, 빈 문자열로 대체하여 정렬
        const companyA = a.company || '';
        const companyB = b.company || '';

        if (companyA > companyB) return 1;
        if (companyA < companyB) return -1;

        // company가 같으면 name을 기준으로 오름차순 정렬
        if (a.name > b.name) return 1;
        if (a.name < b.name) return -1;

        return 0;
      });
      setInfo(data);
    });
  }, []);

  const moveToForm = () => {
    navigate('/accounts/new');
  };

  const columns = [
    {
      key: 'id',
      name: 'ID',
      exp: (item) => <span className={cx('monospace')}>{item.id.substring(0, 8)}</span>,
      align: 'center',
      width: 120,
      onPress: (item) => {
        const element = document.createElement('textarea');
        element.value = item.id;
        document.body.appendChild(element);

        element.select();
        document.execCommand('copy');
        document.body.removeChild(element);
      },
    },
    {
      key: 'company',
      name: 'Company',
      exp: (item) => item.company ?? '-',
      width: 200,
      onPress: (item) => navigate(`/accounts/${item.id}`),
    },
    {
      key: 'name',
      name: 'Name',
      exp: (item) => item.name,
      width: 120,
      onPress: (item) => navigate(`/accounts/${item.id}`),
    },
    {
      key: 'email',
      name: 'Email',
      exp: (item) => item.email ?? '-',
      style: { flex: 1 },
      onPress: (item) => navigate(`/accounts/${item.id}`),
    },
    {
      key: 'robots',
      name: 'Robots',
      exp: (item) => commaNumber(item.robots.length),
      width: 88,
      align: 'center',
      onPress: (item) => navigate(`/accounts/${item.id}`),
    },
    {
      key: 'loggedAt',
      name: 'Last Login',
      exp: (item) => (item.loggedAt ? moment(item.loggedAt).format('YYYY-MM-DD HH:mm:ss') : '-'),
      width: 160,
      align: 'center',
      onPress: (item) => navigate(`/accounts/${item.id}`),
    },
  ];

  return (
    <div className={cx('container')}>
      <TopBar>{info && `Total ${commaNumber(info.length)}`}</TopBar>
      <ContentWrapper>
        <Table columns={columns} data={info} />
      </ContentWrapper>
      <BottomBar>
        <div />
        <div>
          <Button title="New" onClick={moveToForm} />
        </div>
      </BottomBar>
    </div>
  );
};

export default Page;
